<template>
  <va-card title="Menu">
    <va-data-table
      :fields="fields"
      :data="items"
      no-pagination
    >
      <template slot="marker" slot-scope="props">
        <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
      </template>

      <template slot="translations" slot-scope="props">
        <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
      </template>

      <template slot="toggle" slot-scope="props">
        <va-toggle
          small
          v-on:input="togglePublished(props.rowData.id)"
          v-model="props.rowData.published"
        />
      </template>

      <template slot="actions" slot-scope="props">
        <va-button
          small
          color="info"
          :to="{ name: 'shkil-pages-menu-edit', params: { id: props.rowData.id } }"
        >
          Редагувати
        </va-button>
        <va-button
          small
          @click="remove(props.rowData.id)"
          color="danger"
          class="ma-0">
          Видалити
        </va-button>
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      items: [],
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    fields () {
      return [
        {
          name: 'label',
          title: 'Label',
          width: '60%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '10%',
        },
        {
          name: 'position',
          title: 'Position',
          width: '10%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    togglePublished (id) {
      axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-menu/${id}?lang=${this.locale}`, {
        published: !!this.items.find((item) => item.id === id).published,
      })
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    remove (id) {
      if (confirm('Дійсно видалити?')) {
        axios.delete(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-menu/${id}?lang=${this.locale}`)
          .then(response => {
            const idx = this.items.findIndex(u => u.id === id)
            this.items.splice(idx, 1)
            this.showToast('Success')
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/site-menu?lang=${this.locale}`)
        .then(response => {
          console.log(response.data)
          this.items = response.data
          this.items.forEach((item) => {
            item.published = !!item.published
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
